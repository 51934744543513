import { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/

const Sender = lazy(() => import("../views/Starter.js"));
const About = lazy(() => import("../views/About.js"));
const Security = lazy(() => import("../views/ui/Security"));
const Inbox = lazy(() => import("../views/ui/Inbox"));
const Outbox = lazy(() => import("../views/ui/Outbox"));
/*const Buttons = lazy(() => import("../views/ui/Buttons"));
const Cards = lazy(() => import("../views/ui/Cards"));
const Grid = lazy(() => import("../views/ui/Grid"));*/
const Tables = lazy(() => import("../views/ui/Tables"));
/*const Forms = lazy(() => import("../views/ui/Forms"));*/
const Contact = lazy(() => import("../views/ui/Contact"));

/*****Routes******/

const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/sender" /> },
      { path: "/sender", exact: true, element: <Sender /> },
      { path: "/about", exact: true, element: <About /> },
      { path: "/security", exact: true, element: <Security /> },
      { path: "/inbox", exact: true, element: <Inbox /> },
      { path: "/outbox", exact: true, element: <Outbox /> },
      /*{ path: "/buttons", exact: true, element: <Buttons /> },
      { path: "/cards", exact: true, element: <Cards /> },
      { path: "/grid", exact: true, element: <Grid /> },
      { path: "/table", exact: true, element: <Tables /> },
      { path: "/forms", exact: true, element: <Forms /> },*/
      { path: "/contact", exact: true, element: <Contact /> },
    ],
  },
];

export default ThemeRoutes;
