import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./assets/scss/style.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import Loader from "./layouts/loader/Loader";
//import walletLinkModule from "@web3-onboard/walletlink";
import walletConnectModule from '@web3-onboard/walletconnect'
import { Web3OnboardProvider, init } from '@web3-onboard/react'
import injectedModule from '@web3-onboard/injected-wallets'
import {ToastContainer} from "react-toastify";

const injected = injectedModule()

let chainids = [19, 14, 16]

const walletConnect = walletConnectModule({
    bridge: 'https://bridge.walletconnect.org',
    qrcodeModalOptions: {
        mobileLinks: ['rainbow', 'metamask', 'argent', 'trust', 'imtoken', 'pillar']
    },
    connectFirstChainId: true
})

export const w3o = init({
    wallets: [injected, walletConnect],
    chains: [
        {
            id: '0x'+(chainids[0].toString(16)),
            token: 'SGB',
            label: 'Songbird',
            rpcUrl: 'https://rpc.viri.uk/http'
        },
        {
            id: '0x'+(chainids[1].toString(16)),
            token: 'FLR',
            label: 'FLR Mainnet',
            rpcUrl: 'https://rpc.viri.uk/http'
        },
        {
            id: '0x'+(chainids[2].toString(16)),
            token: 'CFLR',
            label: 'Coston Local',
            rpcUrl: 'https://rpc.viri.uk/testhttp'
        }
    ],
    notify: {
        desktop: {
            enabled: true,
            transactionHandler: transaction => {
                console.log({ transaction })
                if (transaction.eventCode === 'txPool') {
                    return {
                        type: 'success',
                        message: 'Your transaction from #1 DApp is in the mempool',
                    }
                }
            },
            position: 'bottomRight'
        },
        mobile: {
            enabled: true,
            transactionHandler: transaction => {
                console.log({ transaction })
                if (transaction.eventCode === 'txPool') {
                    return {
                        type: 'success',
                        message: 'Your transaction from #1 DApp is in the mempool',
                    }
                }
            },
            position: 'bottomRight'
        }
    },
    accountCenter: {
        desktop: {
            position: 'bottomRight',
            enabled: true,
            minimal: true
        },
        mobile: {
            position: 'bottomRight',
            enabled: true,
            minimal: true
        }
    },

})


ReactDOM.render(
    <Web3OnboardProvider web3Onboard={w3o}>
  <Suspense fallback={<Loader />}>

    <HashRouter>
      <App />
    </HashRouter>
  </Suspense>
    </Web3OnboardProvider>
        ,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
